import React from 'react'
import styles from './styles.module.css';
import { Input } from '@material-ui/core';

const TrabajoEnWeDrim = () => {

    const openSelector = () => {
        document.getElementById('selcv').click()
    }




    return (
        <div className={styles.trabajo_wedrim_container}>
            <div className={styles.contenido}>
                <h3>En WeDrim estamos buscando siempre grandes talentos! Si crees que eres uno, no dudes en enviarnos tu CV:</h3>
                <h3 className={styles.titulo_input}>Cuéntanos un poco sobre ti:</h3>
                <Input className={styles.input_msj} type="mesaje" name="mensaje" placeholder="Escribe aqui tu mensaje..." />
                <button onClick={openSelector} className={styles.cv_btn}>
                    Cargar curriculum vitae
                    <input
                        accept='.jpg, .png'
                        style={{ display: 'none' }}
                        id='selcv'
                        type='file'
                    />
                </button>
            </div>
        </div>
    )
}

export default TrabajoEnWeDrim