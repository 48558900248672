import React, { useState, useEffect } from 'react'
import Tarjeta from './Tarjeta'
import styles from './styles.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { apiGetWithParams } from '../../api/index'


const Consultas = props => {

	const [search, setSearch] = useState('')
	const [consultas, setConsultas] = useState([])
	const [page, setPage] = useState(0)

	const PAGE_SIZE = 1


	const obtenerDatos = () => {
		apiGetWithParams('http://localhost:3001/contacto', { page, pageSize: PAGE_SIZE })
			.then(r => {
				// console.log(r.data)
				if (r.status === 'OK') setConsultas(ps => [...ps, ...r.data])
				else console.error('Error al recibir datos')
			})
			.catch(err => console.error(err))
	}

	useEffect(() => {
		obtenerDatos()
	}, [page])

	const obtenerDatosByInput = () => {
		apiGetWithParams('http://localhost:3001/contacto', { search })
			.then(r => {
				// console.log(r.data)
				if (r.status === 'OK') setConsultas(r.data)
				else console.error('Error al recibir datos')
			})
			.catch(err => console.error(err))
	}

	const handleInputChange = (e) => {
		setSearch(e.target.value)
	}


	const handleBusqueda = () => {
		obtenerDatosByInput(search)
	}


	return (
		<div className={styles.cards}>
			<div className={styles.busqueda_notas}>
				<span className={styles.campo_boton}>
					<input
						name="text"
						id="input"
						placeholder='Buscar consulta...'
						onChange={handleInputChange}
						value={search}
					/>
					<button onClick={handleBusqueda} >Buscar</button>
				</span>
			</div>
			<div className={styles.container_tarjetas}>
				{
					consultas.map(
						(consulta, idx) =>
						(
							<Tarjeta
								key={`ct-${idx}`}
								tema_consulta={consulta.subject}
								name={consulta.name + ' ' + consulta.lastname}
								from={consulta.from}
								fecha={consulta.fecha}
								texto={consulta.text}
								id={consulta._id}
							/>
						))
				}
			</div>
			<div className={styles.ver_mas}>
				<button className={styles.ver_todos} onClick={() => setPage(page + 1)} >
					<FontAwesomeIcon className={styles.plus_button} icon={faPlusCircle} />
					<h2>Cargar más</h2>
				</button>
			</div>
		</div>
	)
}

export default Consultas
