import React from 'react'
import styles from './styles.module.css';

const Servicios_PYMES = () => {
    return (
        <div className={styles.pymes_container}>
            <div className={styles.contenido}>
                <h1>EN WEDRIM CREEMOS FIRMEMENTE QUE LAS PYMES (STARTUP) SON EL PILAR FUNDAMENTAL DE LA ECONOMÍA DEL PAÍS, Y ES NUESTRA MISIÓN AYUDARLAS A CRECER.</h1>
                <h3>Con planes especiales de pagos, y apoyo a proyectos, WeDrim dispone de todos sus recursos a fin de potenciar a aquellas empresas con ganas de crecer y desarrollarse!.</h3>
            </div>
        </div>
    )
}

export default Servicios_PYMES