import React from 'react'
import styles from './styles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { useHistory, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'




const Sobre_VolverBtn = () => {

    const history = useHistory();
    const location = useLocation()
    const [t] = useTranslation("buttonBack")


    function handleBack() {
        history.goBack();
    };

    const getRouteDispayNames = (key) => {
        const alias = {
            servicioscorporaciones: "buttonBack.servicioscorporaciones",
            serviciospymes:"buttonBack.serviciospymes",
            productos: "buttonBack.productos",
            clientes: "buttonBack.clientes",
            alianzas: "buttonBack.alianzas",
            trabajeenwedrim: "buttonBack.trabajeenwedrim",
            elegirnos: "buttonBack.elegirnos",
            sobrenosotros: "buttonBack.sobrenosotros",
            nuestrocompromiso: "buttonBack.nuestrocompromiso",
            nuestrosservicios: "buttonBack.nuestrosservicios",
            institucioneseducativas: "buttonBack.institucioneseducativas",
            contacto: "buttonBack.contacto",
            tecnologías: "buttonBack.tecnologías",
            novedades: "buttonBack.novedades",
            consultas: "buttonBack.consultas"
        }
        if (!alias.hasOwnProperty(key)) return ''
        return t(alias[key])
    }

    return (
        <div className={styles.sobre_btn}>
            <h4>{getRouteDispayNames(location.pathname.split("/")[1])}</h4>
            <button onClick={() => handleBack()} className={styles.sobre_nosotros}><FontAwesomeIcon icon={faLongArrowAltLeft} className={styles.back_aroow} /> Volver</button>
        </div>
    )
}

export default Sobre_VolverBtn