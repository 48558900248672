import React from 'react'
import Tarjeta from './Tarjeta'
// import { httpDigitalOceanStorage } from '../../httpMedia'
import styles from './styles.module.css'
import { httpDigitalOceanStorage } from '../../api/server'




const tarjetas = {

    firstRow: [
        {
            imagen: `${httpDigitalOceanStorage}desarrollo_ingenieria_software_blanco.png`,
            texto: 'Desarrollo e Ingeniería de Software',
        },
        {
            imagen: `${httpDigitalOceanStorage}desarrollo_de_app.png`,
            texto: 'Desarrollo de APP',
        },
        {
            imagen: `${httpDigitalOceanStorage}consultoria_blanco.png`,
            texto: 'Consultoría',
        },
        {
            imagen: `${httpDigitalOceanStorage}e_commerce_blanco.png`, 
            texto: 'E- Commerce',
        },
        {
            imagen: `${httpDigitalOceanStorage}servicios_en_la_nube_blanco.png`, 
            texto: 'Servicios en la Nube',
        },
        {
            imagen: `${httpDigitalOceanStorage}desarrollo_video_juegos_2_blanco.png`, 
            texto: 'Desarrollo de Video Juegos',
        },
    ],

    secondRow: [
        {
            imagen: `${httpDigitalOceanStorage}paseos_360_blanco.png`,
            texto: 'Virtualización de Paseos Interactivos 360° (museos, paseos, etc)',
        },
        {
            imagen: `${httpDigitalOceanStorage}automaitzacion_procesos_1_blanco.png`,
            texto: 'Automatización de procesos',
        },
        {
            imagen: `${httpDigitalOceanStorage}diseno web_blanco.png`, 
            texto: 'Diseño y Creación de Páginas Web',
        },
        {
            imagen: `${httpDigitalOceanStorage}diseno grafico_blanco.png`, 
            texto: 'Diseño Gráfico',
        },
        {
            imagen: `${httpDigitalOceanStorage}servicio_mantenimiento_infrastructura.png`,
            texto: 'Servicios de Mantenimiento e Infraestructura',
        },
        {
            imagen: `${httpDigitalOceanStorage}servicio_de_hosting_blanco.png`,
            texto: 'Servicios de Hosting',
        },
    ]

}

const Servicios = (props) => {

    return (
        <div className={styles.servicios_container}>
            <div className={styles.contenido}>
                <h1>SERVICIOS.</h1>
                <div className={styles.container_tarjetas}>
                <span className={styles.fila}>{tarjetas.firstRow.map((tarjeta, idx) => <Tarjeta key={`ct-${idx}`} imagen={tarjeta.imagen} texto={tarjeta.texto} />)}</span>
                <span className={styles.fila}>{tarjetas.secondRow.map((tarjeta, idx) => <Tarjeta key={`ct-${idx}`} imagen={tarjeta.imagen} texto={tarjeta.texto} />)}</span>
            </div>
            </div>
        </div>
    )

}

export default Servicios