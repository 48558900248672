import React from 'react'
import styles from './styles.module.css';

const Compromiso = () => {
    return (
        <div className={styles.compromiso_container}>
            <div className={styles.contenido}>
                <h1>COMPROMETIDOS CON EL MEDIO AMBIENTE, CUIDANDO AL MUNDO CON CADA DESARROLLO.</h1>
                <h3>Nuestras políticas de trabajo están orientadas al cuidado del medio ambiente, garantizando que el uso de nuestras tecnologías tenga el menor impacto ambiental posible.</h3>
            </div>
        </div>
    )
}

export default Compromiso