import React from 'react'
import styles from './styles.module.css';


const SobreNosotrosSegundaVista = () => {
    return (
        <div className={styles.segundaVista_container}>
            <div className={styles.contenido}>
                <h1>FUNDADA EN LOS PRINCIPIOS DE IDONEIDAD, HONESTIDAD, LEALTAD, COMPROMISO Y TRABAJO.</h1>
                <h3>Innovando en cada propuesta, aplicando todos nuestros conocimientos, las mejores y últimas tecnologías
                en pos de brindar un servicio de excelencia y asegurar altos estándares de calidad</h3>
            </div>
        </div>
    )
}

export default SobreNosotrosSegundaVista