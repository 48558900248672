import React from 'react'
import styles from './styles.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

const GoTop = (props) => {

    const goTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (

        <div className={styles.gotop_Btn} onClick={() => goTop()}>
             <FontAwesomeIcon className={styles.arrow_up} icon={faAngleUp} />
        </div>

    )
}

export default GoTop