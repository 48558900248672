import React from 'react'
import styles from './styles.module.css';


const Elegirnos = () => {
    return (
        <div className={styles.nosotros_container}>
            <div className={styles.contenido}>
                <h1>CREEMOS QUE LA MEJOR FORMA DE HACER UN TRABAJO, ES HACERLO BIEN.</h1>
                <h3>Todos nuestros conocimientos y experiencia, al servicio de nuestros clientes, aseguran que los proyectos que emprendan junto a nosotros sean un éxito seguro, ¡potenciándolos y agregando
                    valor!</h3>
            </div>
        </div>
    )
}

export default Elegirnos