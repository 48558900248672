import React from 'react'
import styles from './styles.module.css'
import { Link } from 'react-router-dom'

const Tarjeta = props => {
	
	const { imagen, tema_nota, titulo, autor } = props;

	return (
		<div className={styles.card_descripcion}>
			<div className={styles.tarjeta}>
				<div>
					<p className={styles.tema_nota}>{tema_nota}</p>
					<h2>{imagen}</h2>
				</div>
				<div className={styles.texto}>
					<Link>{titulo}</Link>
					<p>{autor}</p>
				</div>
			</div>
		</div>
	)
}

export default Tarjeta