import React from 'react'
import styles from './styles.module.css';


const SobreNosotros = () => {
    return (
        <div className={styles.nosotros_container}>
            <div className={styles.contenido}>
                <h1>SOMOS LA SOLUCIÓN POR EXCELENCIA QUE SU EMPRESA NECESITA.</h1>
                <h3>Innovando en cada propuesta, aplicando todos nuestros conocimientos, las mejores y últimas tecnologías
                en pos de brindar un servicio de excelencia y asegurar altos estándares de calidad</h3>
            </div>
        </div>
    )
}

export default SobreNosotros