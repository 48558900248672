import React from 'react'
import Tarjeta from './Tarjeta'
import styles from './styles.module.css'


const tarjetas = {

    firstRow: [
        {
            titulo: 'Escuela Virtual y Servicios Educativos',
            texto: 'Plataformas Sincrónica y Asincrónica.',
        },
        {
            titulo: 'SICODE',
            texto: 'Sistema de Rastreo y control de dispositivos.',
        },
       
    ],

    secondRow: [
        {
            titulo: 'AuctionStar',
            texto: 'Sistema de subastas OnLine con e-commerce y sistema de seguridad digital.',
        },
    
        {
            titulo: 'LoadB',
            texto: 'Sistema Balanceador de Carga para servidores y tráfico de internet.',
        },
        {
            titulo: 'WeLibrary',
            texto: 'Biblioteca virtual, para servicios educativos, con más de 5000 obras literarias gratuitas.',
        }
       
    ]

}
const Legales = (props) => {

    return (
        <div className={styles.legales_container}>
            <div className={styles.contenido}>
                <h1>LEGALES.</h1>
                <h3>Algunos de nuestros productos:</h3>
                <div className={styles.container_tarjetas}>
                <span className={styles.fila}>{tarjetas.firstRow.map((tarjeta, idx) => <Tarjeta key={`ct-${idx}`} titulo={tarjeta.titulo} texto={tarjeta.texto} />)}</span>
                <span className={styles.fila}>{tarjetas.secondRow.map((tarjeta, idx) => <Tarjeta key={`ct-${idx}`} titulo={tarjeta.titulo} texto={tarjeta.texto} />)}</span>
                </div>
            </div>
        </div>
    )

}

export default Legales