import React from 'react'
import styles from './styles.module.css';


const Educativas = () => {
    return (
        <div className={styles.educativas_container}>
            <div className={styles.contenido}>
                <h1>WEDRIM CONSIDERA LA EDUCACIÓN COMO UNO DE LOS PILARES FUNDAMENTALES DE LA SOCIEDAD.</h1>
                <h3>Es por eso que Wedrim ha desarrollado una serie de herramientas tecnológicas a fin de apoyar a las instituciones educativas en la inclusión de la tecnología en la Educación.</h3>
            </div>
        </div>
    )
}

export default Educativas