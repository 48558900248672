import React from 'react'
import styles from './styles.module.css';

const Novedades = () => {
    return (
        <div className={styles.novedades_container}>
            <div className={styles.contenido}>
                <h1>NUESTRAS ÚLTIMAS NOVEDADES.</h1>
            </div>
        </div>
    )
}

export default Novedades