import React from 'react'
import styles from './styles.module.css';

const ServiciosGrandesEmpresas = () => {
    return (
        <div className={styles.corporaciones_container}>
            <div className={styles.contenido}>
                <h1>GRANDES COMPAÑIAS CON GRANDES PROYECTOS, NECESITAN GRANDES SOLUCIONES A INMENSOS DESAFÍOS, ¡WEDRIM ESTÁ PREPARADA PARA AFRONTARLOS JUNTO A USTEDES!</h1>
                <h3>El trabajo en equipo, la buena planificación y con políticas claras de trabajo, todos los proyectos llegan a buen puerto. Desde Wedrim, contamos con planes orientados a las grandes
                    compañías para potenciar sus ideas, y lograr desarrollos tecnológicos que revitalicen y proyecten a la compañía más allá de sus expectativas.</h3>
            </div>
        </div>
    )
}

export default ServiciosGrandesEmpresas