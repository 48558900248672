import React from 'react'
import styles from './styles.module.css';


const EducativasTerceraVista = () => {
    return (
        <div className={styles.TerceraVista_container}>
            <div className={styles.contenido}>
                <h1>¡PLATAFORMA ASINCRÓNICA, EL COMPLEMENTO IDEAL PARA EL DICTADO DE CLASES!</h1>
                <h3>Pensada para complementar los estudios, la plataforma ASINCRÓNICA es la herramienta ideal 
                    para apoyar al docente y al estudiante, conectándolos de una manera nunca antes visto, la evolución la educación, ¡DIGITAL!</h3>
            </div>
        </div>
    )
}

export default EducativasTerceraVista