import React from 'react'
import styles from './styles.module.css';


const ElegirnosTerceraVista = () => {
    return (
        <div className={styles.TerceraVista_container}>
            <div className={styles.contenido}>
                <h1>WEDRIM ES UNA GRAN FAMILIA, DISPUESTA A IR A LÍMITES INIMAGINABLES, EN POS DE CUMPLIR SUS OBJETIVOS, POR QUE SOLO AQUEL QUE ESTÁ DISPUESTO A IR MUY LEJOS,
                    SABRÁ QUE TAN LEJOS PUEDE LLEGAR.</h1>
                <h3>¡No habrá límites para nosotros u objetivo inalcanzable, daremos todo por el todo!</h3>
            </div>
        </div>
    )
}

export default ElegirnosTerceraVista