import React from 'react'
import styles from './styles.module.css';

const Footer = () => {
    return (
        <div className={styles.footer_container}>
           <p className={styles.texto_footer} >© 2021 WeDrim S.R.L – Todos los derechos reservados</p>
        </div>
    )
}

export default Footer
