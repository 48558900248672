import React from "react";
import Tarjeta from "./Tarjeta";
import styles from "./styles.module.css";
import { httpDigitalOceanStorage } from '../../api/server'

const tarjetas = {
    movilAndroid: [
        {
            imagen: `${httpDigitalOceanStorage}Java.png`,
            texto: "Java",
        },
        {
            imagen: `${httpDigitalOceanStorage}kotlin.svg`,
            texto: "Kotlin",
        },
        {
            imagen: `${httpDigitalOceanStorage}react.png`,
            texto: "React Native",
        },
    ],

    movilIos: [
        {
            imagen: `${httpDigitalOceanStorage}swift.svg`,
            texto: "Swift",
        },
        {
            imagen: `${httpDigitalOceanStorage}objective-C.png`,
            texto: "ObjectiveC",
        },
        {
            imagen: `${httpDigitalOceanStorage}react.png`,
            texto: "React Native",
        },
    ],

    webFrontend: [
        {
            imagen: `${httpDigitalOceanStorage}java-script.png`,
            texto: "JavaScript",
        },
        {
            imagen: `${httpDigitalOceanStorage}type-script.svg`,
            texto: "TypeScript",
        },
        {
            imagen: `${httpDigitalOceanStorage}html-5.png`,
            texto: "HTML5",
        },
        {
            imagen: `${httpDigitalOceanStorage}css-3.png`,
            texto: "CSS3",
        },
        {
            imagen: `${httpDigitalOceanStorage}angular.png`,
            texto: "Angular",
        },
        {
            imagen: `${httpDigitalOceanStorage}react.png`,
            texto: "React",
        },
    ],

    webBackend: [
        {
            imagen: `${httpDigitalOceanStorage}python_color_(1).png`,
            texto: "Python",
        },
        {
            imagen: `${httpDigitalOceanStorage}node-js.png`,
            texto: "NodeJS",
        },
    ],
    databases: [
        {
            imagen: `${httpDigitalOceanStorage}postgresql.png`,
            texto: "PostgreSQL",
        },
        {
            imagen: `${httpDigitalOceanStorage}mongo-db.png`,
            texto: "Mongodb",
        },
        {
            imagen: `${httpDigitalOceanStorage}mysql.png`,
            texto: "Mysql",
        },
    ],
    infraestructura: [
        {
            imagen: `${httpDigitalOceanStorage}aws.png`,
            texto: "Amazon Web Services",
        },
        {
            imagen: `${httpDigitalOceanStorage}gitlab.png`,
            texto: "GitLab",
        },
        {
            imagen: `${httpDigitalOceanStorage}kubernetes_blanco.png`,
            texto: "Kubernetes",
        }
    ],
    crypto: [
        {
            imagen: `${httpDigitalOceanStorage}aws.png`,
            texto: "NFT",
        },
        {
            imagen: `${httpDigitalOceanStorage}gitlab.png`,
            texto: "BlockChain",
        },
        {
            imagen: `${httpDigitalOceanStorage}kubernetes_blanco.png`,
            texto: "Smart Contract",
        },
        {
            imagen: `${httpDigitalOceanStorage}kubernetes_blanco.png`,
            texto: "Defi",
        }

    ],

};

const Tecnologias = (props) => {
    window.addEventListener("scroll", reveal);

    function reveal() {
        var reveals = document.querySelectorAll(`.${styles.reveal}`);

        for (var i = 0; i < reveals.length; i++) {
            var windowheight = window.innerHeight;
            var revealtop = reveals[i].getBoundingClientRect().bottom;
            var revealpoint = 150;


            // console.log(revealtop, windowheight - revealpoint)
            if (revealtop < windowheight - revealpoint) {
                reveals[i].classList.add(styles.active);
            } else {
                reveals[i].classList.remove(styles.active);
            }
        }
    }

    return (
        <div className={styles.tecnologias_container}>
            <div className={styles.contenido}>
                <h1 className={styles.titulo_seccion}>
                    LAS TECNOLOGÍAS QUE UTILIZAMOS.
                </h1>
                <div className={styles.container_tarjetas}>
                    <h1 className={styles.titulo_plataforma}>Movil</h1>
                    <div className={styles.contenido_movil} id="movil">
                        <span className={styles.reveal}>
                            <h4 className={styles.titulo}>Android</h4>
                            <span className={styles.fila}>
                                {tarjetas.movilAndroid.map((tarjeta, idx) => (
                                    <Tarjeta
                                        key={`ct-${idx}`}
                                        imagen={tarjeta.imagen}
                                        texto={tarjeta.texto}
                                    />
                                ))}
                            </span>
                        </span>
                        <span className={`${styles.ios} ${styles.reveal}`}>
                            <h4 className={styles.titulo}>iOS</h4>
                            <span className={styles.fila}>
                                {tarjetas.movilIos.map((tarjeta, idx) => (
                                    <Tarjeta
                                        key={`ct-${idx}`}
                                        imagen={tarjeta.imagen}
                                        texto={tarjeta.texto}
                                    />
                                ))}
                            </span>
                        </span>
                    </div>
                    <h1 className={styles.titulo_plataforma}>Web</h1>
                    <div className={styles.contenido_web}>
                        <span
                            className={`${styles.frontend} ${styles.reveal}`}
                            id="frontend"
                        >
                            <h4 className={styles.titulo}>FrontEnd</h4>
                            <span className={styles.fila}>
                                {tarjetas.webFrontend.map((tarjeta, idx) => (
                                    <Tarjeta
                                        key={`ct-${idx}`}
                                        imagen={tarjeta.imagen}
                                        texto={tarjeta.texto}
                                    />
                                ))}
                            </span>
                        </span>
                        <span
                            className={`${styles.backend} ${styles.reveal}`}
                            id="backend"
                        >
                            <h4 className={styles.titulo}>BackEnd</h4>
                            <span className={styles.fila}>
                                {tarjetas.webBackend.map((tarjeta, idx) => (
                                    <Tarjeta
                                        key={`ct-${idx}`}
                                        imagen={tarjeta.imagen}
                                        texto={tarjeta.texto}
                                    />
                                ))}
                            </span>
                        </span>
                        <span
                            className={`${styles.databases} ${styles.reveal}`}
                            id="databases"
                        >
                            <h4 className={styles.titulo}>Databases</h4>
                            <span className={styles.fila}>
                                {tarjetas.databases.map((tarjeta, idx) => (
                                    <Tarjeta
                                        key={`ct-${idx}`}
                                        imagen={tarjeta.imagen}
                                        texto={tarjeta.texto}
                                    />
                                ))}
                            </span>
                        </span>
                        <span
                            className={`${styles.infraestructura} ${styles.reveal}`}
                            id="infraestructura"
                        >
                            <h4 className={styles.titulo}>Infraestructura</h4>
                            <span className={styles.fila}>
                                {tarjetas.infraestructura.map(
                                    (tarjeta, idx) => (
                                        <Tarjeta
                                            key={`ct-${idx}`}
                                            imagen={tarjeta.imagen}
                                            texto={tarjeta.texto}
                                        />
                                    )
                                )}
                            </span>
                        </span>
                        <span
                            className={`${styles.Crypto} ${styles.reveal}`}
                            id="crypto"
                        >
                            <h4 className={styles.titulo}>Crypto</h4>
                            <span className={styles.fila}>
                                {tarjetas.crypto.map(
                                    (tarjeta, idx) => (
                                        <Tarjeta
                                            key={`ct-${idx}`}
                                            imagen={tarjeta.imagen}
                                            texto={tarjeta.texto}
                                        />
                                    )
                                )}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tecnologias;
