import React, { useState} from 'react'
import styles from './styles.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import ModalEliminar from '../Modales/ModalEliminar'


const Tarjeta = props => {

	const { tema_consulta, from, name, texto, id } = props;

	const [showDetail, setShowDetail] = useState(false)
	const [eliminar, setEliminar] = useState(false)

	const handleEliminar = (id) => {
		setEliminar(true)
		// setIdEliminar(id)
	}

	const handleCancelar = () => {
		setEliminar(false)
	}


	// console.log(id) //props id, OK

	return (
		<div className={styles.tarjeta} onClick={() => setShowDetail(ps => !ps)} >
			<FontAwesomeIcon
				className={styles.delete_button}
				icon={faTrash}
				onClick={() => handleEliminar(id)}
			/>
			<div className={styles.texto}>
				<h2>{tema_consulta}</h2>
				<p>{name}</p>
				<p>{from}</p>
				{showDetail ?
					<p className={styles.cuerpo_consulta}>
						{texto}
						<a className={styles.mailto} href="mailto:ejemplo@mail.com">Contactar por correo</a>
					</p>
					: null}

				<ModalEliminar
					closeFromParent={handleCancelar}
					open={eliminar}
					id={id}
				/>
			</div>
		</div>
	)
}

export default Tarjeta