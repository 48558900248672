import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import ScrollToTop from './components/ScrollToTop'
import Sobre_VolverBtn from './components/Sobre_VolverBtn'
import Barra_Redes from './components/Barra_Redes'
import QuienesSomos from './components/QuienesSomos'
import SobreNosotros from './components/SobreNosotros';
import SobreNosotrosSegundaVista from './components/SobreNosotrosSegundaVista';
import SobreNosotrosTerceraVista from './components/SobreNosotrosTerceraVista';
import Servicios from './components/Servicios'
import Compromiso from './components/Compromiso';
import CompromisoSegundaVista from './components/CompromisoSegundaVista';
import Elegirnos from './components/Elegirnos';
import ElegirnosSegundaVista from './components/ElegirnosSegundaVista'
import ElegirnosTerceraVista from './components/ElegirnosTerceraVista';
import TrabajoEnWeDrim from './components/TrabajoEnWeDrim'
import AlianzasEstrategicas from './components/AlianzasEstrategicas';
import Clientes from './components/Clientes';
import Productos from './components/Productos'
import Servicios_PYMES from './components/Servicios_PYMES'
import ServiciosGrandesEmpresas from './components/ServiciosGrandesEmpresas'
import Educativas from './components/Educativas'
import EducativasSegundaVista from './components/EducativasSegundaVista'
import EducativasTerceraVista from './components/EducativasTerceraVista'
import Contacto from './components/Contacto'
import Tecnologias from './components/Tecnologias';
import Novedades from './components/Novedades'
import NovedadesSegundaVista from './components/NovedadesSegundaVista'
import Consultas from './components/Consultas'
import Legales from './components/Legales'
import GoTop from './components/GoTop'
import Footer from './components/Footer'

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />

        <ScrollToTop />
        <Barra_Redes/>
        <Switch>
          <Route exact path='/'>
            <QuienesSomos />
          </Route>
          <>
            <Sobre_VolverBtn />
            <Route path='/legales'>
              <Legales />
              <GoTop />
            </Route>
            <Route path='/servicioscorporaciones'>
              <ServiciosGrandesEmpresas />
              <GoTop />
            </Route>
            <Route path='/serviciospymes'>
              <Servicios_PYMES />
              <GoTop />
            </Route>
            <Route path='/productos'>
              <Productos />
              <GoTop />
            </Route>
            <Route path='/clientes'>
              <Clientes />
              <GoTop />
            </Route>
            <Route path='/alianzas'>
              <AlianzasEstrategicas />
              <GoTop />
            </Route>
            <Route path='/trabajeenwedrim'>
              <TrabajoEnWeDrim />
            </Route>
            <Route path='/elegirnos'>
              <Elegirnos />
              <ElegirnosSegundaVista />
              <ElegirnosTerceraVista />
              <GoTop />
            </Route>
            <Route path='/sobrenosotros'>
              <SobreNosotros />
              <SobreNosotrosSegundaVista />
              <SobreNosotrosTerceraVista />
              <GoTop />
            </Route>
            <Route path='/nuestrocompromiso'>
              <Compromiso />
              <CompromisoSegundaVista />
              <GoTop />
            </Route>
            <Route path='/nuestrosservicios'>
              <Servicios />
            </Route>
            <Route path='/institucioneseducativas'>
              <Educativas />
              <EducativasSegundaVista/>
              <EducativasTerceraVista/>
              <GoTop />
            </Route>
            <Route path='/contacto'>
              <Contacto />
              <GoTop/>
            </Route>
            <Route path='/tecnologías'>
              <Tecnologias />
              <GoTop/>
            </Route>
            <Route path='/novedades'>
              <Novedades />
              <NovedadesSegundaVista/>
              <GoTop/>
            </Route>
            <Route path='/consultas'>
              <Consultas/>
              <GoTop/>
            </Route>
          </>
        </Switch>
        <Footer />
      </ Router>

    </div>
  );
}

export default App;
